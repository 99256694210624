@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Noto Sans";
  src: url("../public/font/NotoSans-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../public/font/NotoSans-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../public/font/NotoSans-Thin.ttf");
  font-weight: 100;
}


body {
  @apply bg-primary text-secondary;
}